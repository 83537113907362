import {
  DesktopOutlined,
  SnippetsOutlined,
  BarcodeOutlined,
  CarOutlined,
  UsergroupAddOutlined,
  MoneyCollectOutlined,
  FileAddOutlined,
  BarChartOutlined,
  ToolOutlined,
  ContactsOutlined,
  ProfileOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { ROUTES } from "@/constants";
import { Link } from "react-router-dom";

export const menuItems = [
  {
    key: "home",
    label: <Link to={ROUTES.home}>Home</Link>,
    icon: <DesktopOutlined />,
    visibility: ["admin", "staff", "manager", "finance"],
    context: ["operation", "financial"],
  },
  {
    key: "orders",
    label: <Link to={ROUTES.orders}>Orders</Link>,
    icon: <SnippetsOutlined />,
    visibility: ["admin", "manager", "staff"],
    context: ["operation"],
  },
  {
    key: "lineItems",
    label: <Link to={ROUTES.lineItems}>LineItems</Link>,
    icon: <ProfileOutlined />,
    visibility: ["admin", "manager", "staff"],
    context: ["operation"],
  },
  {
    key: "products",
    label: <Link to={ROUTES.products}>Products</Link>,
    icon: <BarcodeOutlined />,
    visibility: ["admin", "manager", "staff"],
    context: ["operation"],
  },
  {
    key: "buyer",
    label: <Link to={ROUTES.buyer}>Buyer</Link>,
    icon: <ContactsOutlined />,
    visibility: ["admin", "manager", "staff"],
    context: ["operation"],
  },
  {
    key: "Quotations",
    label: <Link to={ROUTES.quotations}>Quotations</Link>,
    icon: <ProfileOutlined />,
    visibility: ["admin", "manager"],
    context: ["operation"],
  },
  {
    key: "logistic",
    label: <Link to={ROUTES.logistic}>Logistic</Link>,
    icon: <CarOutlined />,
    visibility: ["admin", "staff", "manager"],
    context: ["operation"],
  },
  {
    key: "customers",
    label: "Customers",
    icon: <UsergroupAddOutlined />,
    children: [
      { label: <Link to={ROUTES.customers}>Customers</Link>, key: "regularCustomers" },
      { label: <Link to={ROUTES.franchises}>Franchises</Link>, key: "franchises" },
    ],
    visibility: ["admin", "staff", "manager", "finance"],
    context: ["operation", "financial"],
  },
  {
    key: "payments",
    label: <Link to={ROUTES.payments}>Payments</Link>,
    icon: <MoneyCollectOutlined />,
    visibility: ["admin", "finance"],
    context: ["financial"],
  },
  {
    key: "/payments/new",
    label: <Link to={ROUTES.paymentsNew}>New payment</Link>,
    icon: <MoneyCollectOutlined />,
    visibility: ["admin", "finance"],
    context: ["financial"],
  },
  {
    key: "statements",
    label: <Link to={ROUTES.statements}>Statements</Link>,
    icon: <FileAddOutlined />,
    visibility: ["admin", "finance"],
    context: ["financial"],
  },
  {
    key: "invoices",
    label: <Link to={ROUTES.invoices}>Invoices</Link>,
    icon: <SnippetsOutlined />,
    isDevOnly: false,
    visibility: ["admin", "finance"],
    context: ["financial"],
  },
  {
    key: "orders-report",
    label: <Link to="/orders-report">Orders report</Link>,
    icon: <BarChartOutlined />,
    visibility: ["admin", "manager"],
    context: ["operation"],
  },
  {
    key: "settings",
    label: "Settings",
    icon: <SettingOutlined />,
    children: [
      { label: <Link to={"/logistic-setup"}>Logistic setup</Link>, key: "logistic-setup" },
    ],
    visibility: ["admin", "staff", "manager"],
    context: ["operation"],
  },
  {
    key: "sales-portal",
    label: <Link to="/sales-portal">Sales portal</Link>,
    icon: <MoneyCollectOutlined />,
    isDevOnly: true,
    visibility: ["admin", "sales"],
    context: ["sales"],
  },
  {
    key: "playground",
    label: <Link to={ROUTES.playground}>Playground</Link>,
    icon: <ToolOutlined />,
    isDevOnly: true,
    visibility: ["admin", "finance", "staff", "manager"],
    context: ["dev"],
  },
];

export const salesColumns = [
  {
    key: "sales-portal",
    label: <Link to="/sales-portal">Customers</Link>,
    icon: <UsergroupAddOutlined />,
  },
];
